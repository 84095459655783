export const event_types = [
    {label: "Конгресс 2024 (Гости)", value: "kongres2024_guest"},
    {label: "Конгресс 2024 (Партнеры)", value: "kongres2024_partner"},
    {label: "Мероприятие 1", value: "event1"},
    {label: "кик офф 2024", value: "event2"},
    {label: "лето москва", value: "summer_moscow"},
    {label: "лето челябинск", value: "summer_chelyaba"},
    {label: "лето иркутск", value: "summer_irkut"},
    {label: "лето краснодар", value: "simmer_krasno"},
    {label: "без типа", value: "non_type"},
    {label: "кик офф 2025 (Гости)", value: "kickoff2025_guest"},
    {label: "кик офф 2025 (Партнеры)", value: "kickoff2025_partner"},

    {label: "Форум 2025 (москва)", value: "summer_forum_moscow_2025"},
    {label: "Форум 2025 (челябинск)", value: "summer_forum_chelyaba_2025"},
    {label: "Форум 2025 (краснодар)", value: "summer_forum_krasnodar_2025"},
    {label: "Форум 2025 (иркутск)", value: "summer_forum_irkutsk_2025"},

    {label: "Академия 2025 (Улан-Удэ)", value: "academ_ulan_2025"},
    {label: "Академия 2025 (Санкт-Петербург)", value: "academ_spb_2025"},
    {label: "Академия 2025 (Иркутск)", value: "academ_irk_2025"},
]
export const ticket_type = [
    {label: "Подтвержденные", value: true},
    {label: "Не потдвержденные", value: false}
]